import { render, staticRenderFns } from "./gridCategoryCards.vue?vue&type=template&id=84276896&scoped=true&"
import script from "./gridCategoryCards.vue?vue&type=script&lang=js&"
export * from "./gridCategoryCards.vue?vue&type=script&lang=js&"
import style0 from "./gridCategoryCards.vue?vue&type=style&index=0&id=84276896&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84276896",
  null
  
)

export default component.exports